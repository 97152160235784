import React from "react"
import { Map, fromJS } from "immutable"
import PropTypes from "prop-types"
import ContentfulPage from "highline/components/contentful/contentful_component"
import ContentfulArticle from "highline/components/contentful/contentful_article"

import styles from "highline/styles/components/contentful/contentful_article.module.css"

const ArticleOrPage = ({ isArticle, ...otherProps }) => {
  return isArticle ? <ContentfulArticle {...otherProps} /> : <ContentfulPage {...otherProps} />
}

ArticleOrPage.propTypes = {
  isArticle: PropTypes.bool,
}

ArticleOrPage.defaultProps = {
  isArticle: false,
}

export default ArticleOrPage

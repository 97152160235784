import React from "react"
import PropTypes from "prop-types"
import Drawer from "highline/components/application/drawer"
import DrawerNavBar from "highline/components/application/drawer_nav_bar"
import classNames from "classnames"
import * as Navigate from "highline/utils/navigate"
import Cart from "highline/components/cart/cart"
import UserAuth from "highline/components/auth/user_auth"
import ProductPreviewContainer from "highline/containers/product_preview_container"
import { BackArrowIcon } from "highline/components/icons"
import ContentfulContainer from "highline/containers/contentful_container"
import Cta from "highline/components/cta"

import styles from "highline/styles/components/right_drawer.module.css"

const contentTypesAndPresentations = {
  auth: "Sign In",
  cart: "Cart",
  contentful: "Content",
  quickShop: "Quick Shop",
  "": "",
}

const contentTypes = Object.keys(contentTypesAndPresentations)

class RightDrawer extends React.PureComponent {
  static propTypes = {
    cartLayout: PropTypes.oneOf(["cart_standard_width", "cart_double_width"]),
    cmsContent: PropTypes.object,
    contents: PropTypes.oneOf(contentTypes),
    displayTitle: PropTypes.string,
    isSinglePageCheckoutEnabled: PropTypes.bool,
    isGuestCheckoutEnabled: PropTypes.bool,
    isCartReady: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onClickGuestCheckout: PropTypes.func,
    onLoad: PropTypes.func,
    onOpen: PropTypes.func,
    onSwapContents: PropTypes.func,
    overlayDuration: PropTypes.number,
    swappableContents: PropTypes.oneOf(contentTypes),
  }

  static defaultProps = {
    cartLayout: "cart_double_width",
    contents: "",
    isCartReady: false,
    isSinglePageCheckoutEnabled: false,
    isGuestCheckoutEnabled: false,
    isOpen: false,
    onClose: () => {},
    onClickGuestCheckout: () => {},
    onLoad: () => {},
    onOpen: () => {},
    onSwapContents: () => {},
    overlayDuration: 500,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isCartReady && this.props.isCartReady) {
      this.props.onLoad()
    }
  }

  render() {
    const {
      cartLayout,
      cmsContent,
      contents,
      displayTitle,
      isSinglePageCheckoutEnabled,
      isGuestCheckoutEnabled,
      isOpen,
      onClose,
      onClickGuestCheckout,
      onOpen,
      onSwapContents,
      overlayDuration,
      swappableContents,
    } = this.props

    let isCart, isAuth, isQuickShop, isContentfulContent, centerContent
    const guestCheckoutText = "Continue as a guest"
    const dividerText = "Already have an account?"
    const authRedirectPath = Navigate.paths.get(
      isSinglePageCheckoutEnabled ? "singlePageCheckout" : "checkout"
    )

    switch (contents) {
      case "quickShop":
        centerContent = "Quick Shop"
        isQuickShop = true
        break
      case "cart":
        centerContent = "Your Cart"
        isCart = true
        break
      case "contentful":
        centerContent = displayTitle
        isContentfulContent = true
        break
      case "auth":
        isAuth = true
        break
    }

    return (
      <div className={classNames("container", "right-drawer-container")}>
        <Drawer
          cartLayout={cartLayout}
          centerContent={centerContent}
          isOpen={isOpen}
          position="right"
          onRequestClose={onClose}
          overlayDuration={overlayDuration}
          isCart={isCart}
        >
          <DrawerNavBar
            centerContent={centerContent}
            leftCTA={swappableContents ? <BackArrowIcon /> : null}
            leftCTAPrompt={
              swappableContents ? `Open ${contentTypesAndPresentations[swappableContents]}` : null
            }
            rightCTA={isOpen ? "Close" : null}
            rightCTAPrompt={isCart ? "Close the Cart" : null}
            onClickRightCTA={() => {
              onClose(contents)
            }}
            onClickLeftCTA={
              swappableContents
                ? () => {
                    onSwapContents(swappableContents)
                  }
                : null
            }
          />
          {isCart && <Cart onLoad={onOpen} />}
          {isAuth && (
            <>
              {isGuestCheckoutEnabled && (
                <section className={styles.guestCheckoutSection}>
                  <Cta layout="primary" href={authRedirectPath} onClick={onClickGuestCheckout}>
                    {guestCheckoutText}
                  </Cta>
                  <div className={classNames(styles.divider, styles.marginTopLg)}>
                    {dividerText}
                  </div>
                </section>
              )}
              <UserAuth redirectOnSuccessUrl={authRedirectPath} layout="drawer" />
            </>
          )}
          {isQuickShop && <ProductPreviewContainer />}
          {isContentfulContent && <ContentfulContainer content={cmsContent} />}
        </Drawer>
      </div>
    )
  }
}

export default RightDrawer

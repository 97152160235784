import { connect } from "react-redux"
import { isFeatureEnabled } from "highline/utils/abtasty_helper"

import {
  RIGHT_DRAWER_OVERLAY_DURATION,
  rightDrawerCloseAsync,
  openRightDrawer,
  swapRightDrawerContents,
} from "highline/redux/actions/right_drawer_actions"
import { productPreviewCleanAfterClickClose } from "highline/redux/actions/product_preview_actions"
import {
  cartAddAsGiftAsync,
  cartCloseClicked,
  loadCartItemCountAsync,
  guestCheckoutClicked,
} from "highline/redux/actions/cart_actions"

import RightDrawer from "highline/components/application/right_drawer"

const mapStateToProps = (state) => {
  return {
    cmsContent: state.getIn(["rightDrawer", "cmsContent", "content"]),
    contents: state.getIn(["rightDrawer", "contents"]),
    displayTitle: state.getIn(["rightDrawer", "cmsContent", "displayTitle"]),
    isCartReady: state.getIn(["cart", "isReady"]),
    isSinglePageCheckoutEnabled: isFeatureEnabled("singlePageGuestCheckout"),
    isGuestCheckoutEnabled: state.getIn(["order", "isGuestCheckoutEnabled"]),
    isOpen: state.getIn(["rightDrawer", "isOpen"]),
    overlayDuration: RIGHT_DRAWER_OVERLAY_DURATION,
    swappableContents: state.getIn(["rightDrawer", "swappableContents"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: (content) => {
      dispatch(cartAddAsGiftAsync())
      dispatch(rightDrawerCloseAsync())
      dispatch(cartCloseClicked())
      if (content === "quickShop") {
        dispatch(productPreviewCleanAfterClickClose())
      }
    },
    onLoad: () => {
      dispatch(loadCartItemCountAsync())
    },
    onOpen: () => {
      dispatch(openRightDrawer())
    },
    onSwapContents: (contents) => {
      dispatch(swapRightDrawerContents(contents))
    },
    onClickGuestCheckout: () => {
      dispatch(guestCheckoutClicked())
    },
  }
}

const RightDrawerContainer = connect(mapStateToProps, mapDispatchToProps)(RightDrawer)

export default RightDrawerContainer
